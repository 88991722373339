<template>
    <section class="all-templates-view">
        <el-row type="flex" justify="center" align="center">
            <el-col :span="22">
                <el-row style="margin-top: -10px; margin-bottom: 5px;">
                    <router-link to="/apps">
                        <el-button size="mini"> <i class="el-icon-back"></i> back</el-button>
                    </router-link>
                </el-row>
                <el-row style="margin-bottom: 20px">
                    <el-col :lg="6" :md="6" :sm="24" :xs="24">

                        <h3 class="title fw-normal text-dark mb-0 ls-05">{{ 'All Solutions' }}</h3>
                    </el-col>
                    <el-col :lg="18" :md="18" :sm="24" :xs="24" style="text-align: right" class="top-buttons-wrapper">
                        <el-select v-model="selectedStatus" placeholder="Select" style="margin-right: 15px;" clearable>
                            <el-option v-for="item in publishStatus" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                        <el-input class="search" placeholder="Search" v-model="search_string" clearable @input="loadData"
                            :style="getIsMobile ? 'width:130px' : 'width:170px;'"></el-input>
                        <el-button plain :style="getIsMobile ? 'width:130px' : 'width:170px;'" class="create-btn px-4 ml-2"
                            type="primary" @click="newSolution">Build New Solution</el-button>
                    </el-col>
                </el-row>
                <div v-loading="configureLoading" element-loading-text="Loading..."
                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
                    class="vue-data-table-default">
                    <data-tables-server :fullscreen="true" :total="total" :data="allApplications"
                        :current-page.sync="currentPage" :page-size="pageSize" :pagination-props="paginationProps"
                        style="width: 100%" v-if="!getIsMobile" @query-change="loadData" @sort-change="sortChange">
                        <el-table-column label="Name" id="name" prop="title" width="200" fixed="left" sortable="custom">
                            <template slot-scope="scope">
                                <a class="doc-title">
                                    {{ scope.row.name | truncate(15, "...") }}
                                </a>
                            </template>
                        </el-table-column>
                        <el-table-column label="Actions" prop="action" class="action" width="200">
                            <template slot-scope="scope">
                                <!-- slot-scope="scope" -->
                                <ul class="action-buttons hover-buttons">
                                    <li>
                                        <el-dropdown>
                                            <el-button size="mini" class="el-dropdown-link px-2">
                                                Actions
                                                <i class="el-icon-arrow-down el-icon--right"></i>
                                            </el-button>
                                            <el-dropdown-menu slot="dropdown">
                                                <a @click="onView(scope.row)" style="border: none">
                                                    <el-dropdown-item
                                                        command="view"><i class="el-icon-view"></i>View</el-dropdown-item>
                                                </a>
                                                <a v-if="scope.row.publish == 'HOLD'" @click="onClone(scope.row)"
                                                    style="border: none">
                                                    <el-dropdown-item
                                                        command="clone"><i class="el-icon-connection"></i>Clone</el-dropdown-item>
                                                </a>
                                                <a v-if="scope.row.publish == 'DECLINED'" @click="onDelete(scope.row)"
                                                    style="border: none">
                                                    <el-dropdown-item
                                                        command="clone"><i class="el-icon-delete"></i>Delete</el-dropdown-item>
                                                </a>
                                                <a v-if="scope.row.publish == 'HOLD'" @click="onApprove(scope.row)"
                                                    style="border: none">
                                                    <el-dropdown-item
                                                        command="approve" > <i class="el-icon-check" style="color:green"></i>Approve</el-dropdown-item>
                                                </a>
                                                <a v-if="scope.row.publish == 'HOLD'" @click="onDecline(scope.row)"
                                                    style="border: none">
                                                    <el-dropdown-item 
                                                        command="decline"><i class="el-icon-close" style="color:red"></i>Decline</el-dropdown-item>
                                                </a>
                                                <a v-if="scope.row.publish == 'ACTIVE'" @click="onInactive(scope.row)"
                                                    style="border: none">
                                                    <el-dropdown-item 
                                                        command="decline"><i class="el-icon-edit"></i>Make inactive</el-dropdown-item>
                                                </a>
                                                <a v-if="scope.row.publish == 'INACTIVE'" @click="onActive(scope.row)"
                                                    style="border: none">
                                                    <el-dropdown-item 
                                                        command="decline"><i class="el-icon-edit"></i>Make active</el-dropdown-item>
                                                </a>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </li>
                                </ul>
                            </template>
                        </el-table-column>
                        <el-table-column label="Status" id="status" prop="publish" width="200" sortable="custom">
                            <template slot-scope="scope">
                                <div class="d-flex activestatus">
                                    <div :class="scope.row.publish + ' circle'"></div>
                                    <div>
                                        <p v-if="scope.row.publish == 'ACTIVE'">Active</p>
                                        <p v-else-if="scope.row.publish == 'INACTIVE'">Inactive</p>
                                        <p v-else-if="scope.row.publish == 'HOLD'">Hold</p>
                                        <p v-else>Declined</p>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Developed By" prop="last_modified" sortable="custom" min-width="200">
                            <template slot-scope="scope">
                                <span v-if="scope.row &&scope.row.developed_by&&scope.row.developed_by.name">
                                    {{ scope.row.developed_by.name }}
                                </span>
                                <span v-else>-</span>

                            </template>
                        </el-table-column>
                        <el-table-column label="Last Modified" prop="developed_by.name" sortable="custom" min-width="200">
                            <template slot-scope="scope">
                                {{
                                    scope.row.updated_at | globalDateTimeFormat
                                }}
                            </template>
                        </el-table-column>
                        
                    </data-tables-server>
                </div>
                <data-tables-server :fullscreen="true" :total="total" :data="allApplications"
                    :current-page.sync="currentPage" :page-size="pageSize" :pagination-props="paginationProps"
                    style="width: 100%" v-if="getIsMobile" @query-change="loadData" @sort-change="sortChange"
                    class="entities-list-mobile">
                    <el-table-column type="expand">
                        <template slot-scope="scope">
                            <div class="expand-column-item">
                                <div class="expand-column-item-label">Status:</div>
                                <div class="expand-column-item-content">
                                    <div class="d-flex activestatus">
                                        <!-- <div :class="scope.row.status.toLowerCase() + ' circle'"></div> -->
                                        <div>
                                            <p v-if="scope.row.status == 'ACTIVE'">Active</p>
                                            <p v-else-if="scope.row.status == 'INACTIVE'">Inactive</p>
                                            <p v-else>Declined</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="expand-column-item">
                                <div class="expand-column-item-label">Last Modified:</div>
                                <div class="expand-column-item-content">
                                    <template v-if="scope.row.updated_at">
                                        <span class="fs-8 d-inline-block pr-2 text-muted">{{ scope.row.updated_at |
                                            globalDateTimeFormat }}</span>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Name" id="name" min-width="120" fixed>
                        <template slot-scope="scope">
                            <a class="doc-title">
                                {{ scope.row.name | truncate(15, "...") }}
                            </a>
                        </template>
                    </el-table-column>

                    <el-table-column prop="action" min-width="100" fixed>
                        <template slot-scope="scope">
                            <ul class="action-buttons hover-buttons">
                                <li>
                                    <el-dropdown>
                                        <el-button size="mini" class="el-dropdown-link px-2">
                                            Actions
                                            <i class="el-icon-arrow-down el-icon--right"></i>
                                        </el-button>
                                        <el-dropdown-menu slot="dropdown">
                                            <a @click="onView(scope.row)" style="border: none">
                                                <el-dropdown-item icon="el-icon-edit-outline"
                                                    command="view">View</el-dropdown-item>
                                            </a>
                                            <a @click="onDecline(scope.row)" style="border: none">
                                                <el-dropdown-item icon="el-icon-delete"
                                                    command="delete">Delete</el-dropdown-item>
                                            </a>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </li>
                            </ul>
                        </template>
                    </el-table-column>
                </data-tables-server>
            </el-col>
            <el-dialog :title=SelectedAppData.name :visible.sync="viewAppDialog" class="view-application"
                v-loading="appLoading" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :close-on-click-modal="false"
                :close-on-press-escape="false" width="40%">
                <el-card class="box-card mb-1 card" shadow="hover">
                    <div class="card-content">
                        <div class="left-content">
                            <img v-if="SelectedAppData.icon"
                                :src="require('@/assets/img/icons/menu/' + SelectedAppData.icon)" class="imgSize" />
                            <img v-else src="require('@/assets/img/icons/menu/company-document-2.svg')" alt="menu"
                                class="imgSize" />
                            <div class="text-wrapper">
                                <span>
                                    <h5 style="margin-bottom: -1px;">{{ SelectedAppData.name }}</h5>
                                    <p v-if="SelectedAppData.developed_by && SelectedAppData.developed_by.name">{{ SelectedAppData.developed_by.name }}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="text text-justify text-ellipsis mt-3" >
                        <span v-if="SelectedAppData.description">{{ SelectedAppData.description }}</span>
                        <span v-else>
                            Employee management involves the activities and performance of employees within an organization.
                        </span>
                    </div>
                    <div>
                        <h6 v-if="SelectedAppData.version && SelectedAppData.version[0].entities"> Entities : {{
                            SelectedAppData.version[0].entities.length }}</h6>
                        <h6 v-if="SelectedAppData.version && SelectedAppData.version[0].menuManagementWithRoles"> Roles : {{
                            SelectedAppData.version[0].menuManagementWithRoles.length }}</h6>
                        <h6 v-if="SelectedAppData.version && SelectedAppData.version[0]"> Version : {{
                            SelectedAppData.version[0].version }}</h6>
                        <h6 v-if="SelectedAppData"> Status : {{ SelectedAppData.publish }}</h6>

                    </div>
                </el-card>
            </el-dialog>
        </el-row>
        <el-dialog
          :visible.sync="appLoadingDialogVisible"
          width="35%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
          center
        >
          <div class="text-center">
            <img
              src="@/assets/img/importDataLoading.gif"
              class="import-loading-gif"
            />
            <p>Please wait</p>
            <h1>Your Application cloning is in progress...</h1>
          </div>
      </el-dialog>

      <el-dialog :visible.sync="installApplicationPopUp" width="35%" class="installApplicationPopUp">
      <div slot="title" class="dialog-header">
        <h3>{{ currentApp.name }}</h3>
        <!-- <i class="el-icon-close" @click="dialogVisible = false"></i> -->
      </div>
      <div class="dialog-body">

        <div class="division-two">
          <h3>App Name</h3>
          <el-input placeholder="Enter App name" v-model="currentApp.newName" ></el-input><br><br>
          <div class="buttons">
            <el-button @click="installApplicationPopUp = false">Cancel</el-button>
            <el-button type="primary" @click="cloneApplication(currentApp)">Install</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
    title="Decline application" 
    :visible.sync="declineReasonPopup"
    :close-on-click-modal="false"
    :close-on-press-escape="false" 
    width="40%"
    >
    <label>Reason for declining application</label>
    <el-input 
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4}"
        v-model="reasonForDecline" 
        placeholder="Reason for decline"
        style="margin:10px 5px">
    </el-input>
    <div class="buttons">
            <el-button @click="declineReasonPopup = false">Cancel</el-button>
            <el-button type="primary" @click="declineReason(currentApp)">Decline</el-button>
          </div>
    </el-dialog>
    </section>
</template>
<script>
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import NavigationHelper from "@/mixins/navigationHelper";

export default {
    name: "Entity-EntitiesList",
    data() {
        return {
            search_string: "",
            configureLoading: false,
            total: 0,
            activeStep: 0,
            currentPage: 1,
            pageSize: 0,
            page: 1,
            newEntityModal: false,
            entityName: "",
            description: "",
            isAccount: false,
            entity_type: "GENERAL",
            modalLoading: false,
            allApplications: [],
            showAddGroupDialog: false,
            groupName: "",
            loadingSaveGroup: false,
            entityGroupsData: [],
            allTemplates: [],
            allRoles: [],
            newAppText: 'Loading....',
            publishApp: {},
            viewAppDialog: false,
            appLoading: false,
            SelectedAppData: {},
            approvalsCompanyId:'',
            declineReasonPopup : false,
            reasonForDecline :'',
            publishStatus: [{
                value: 'HOLD',
                label: 'Hold'
            }, {
                value: 'DECLINED',
                label: 'Declined'
            }, {
                value: 'ACTIVE',
                label: 'Active'
            }, {
                value: 'INACTIVE',
                label: 'Inactive'
            },
        ],
            selectedStatus: '',
            appLoadingDialogVisible:false,
            installApplicationPopUp : false,
            currentApp:{
                isAdminClone:false,IsInstallAsWorkspace:false,newName:''
            }
        };
    },
    mixins: [PermissionsHelper, NavigationHelper],
    computed: {
        ...mapGetters("auth", [
            "getAuthenticatedUser",
            "getActiveWorkspace",
            "getUserType"
        ]),
        ...mapGetters("navigationOpen", [
            "getIsOpen",
            "getIsCollapse",
            "getIsMobile"
        ]),
        ...mapGetters("company", [
            "getCompanyDetails",
            "getUpdateCompany",
            "getCompanyLogo",
            "getSingleCompanyLogo",
        ]),
        ...mapGetters("apps", [
            "getAllApps", 
            "getAppErrors", 
            "getInstallationStatus",
            "getAllCategories",
            "getAppInstallationErrors",
            "getAppUpdatedStatus",
            "getAppUpdatedErrors",
            "getAppDeletionStatus",
            "getAppDeletionErrors"
        ]),

        paginationProps() {
            return {
                pageSizes: this.pageSizes || [10, 20, 50]
            };
        }
    },
    async mounted() {
        await this.getCompanyInfo();
        this.approvalsCompanyId = process.env.VUE_APP_APPROVALS_COMPANY_ID;
        if (this.getCompanyDetails._id != this.approvalsCompanyId){
            this.$notify.error({
                title:"Error",
                message : "You don't have permissions to access approvals"
            })
            this.$router.push({path : "/dashboard"});
        }
        else{
            await this.fetchApplications();
        }
    },
    methods: {
        beforeDestroy() {
            this.$store.commit(
                "navigationOpen/setCurrentMenu",
                {},
                {
                    root: true
                }
            );
        },
        /*  ***Fetch Groups  end  **** */
        async sortChange(a) {
            this.order_by = a.prop;
            this.order_type = a.order == "ascending" ? "asc" : "desc";
            this.fetchApplications();
            if (a.order == null) {
                this.order_by = null;
                this.order_type = null;
                this.fetchApplications();
            }
        },
        async loadData(data) {
            if ((data && data.type != "init") || !data) {
                this.pageSize = data.pageSize;
                this.page = data.page;
                await this.fetchApplications();
            }
        },
        async onView(document) {
            this.viewAppDialog = true
            this.SelectedAppData = document
        },

        async newSolution() {
            this.$router.push({ path: "/apps/upload" });
        },
        async fetchApplications() {
            this.configureLoading = true;
            try {
                // this.configureLoading = true;
                // let params = {
                //   get_all: true
                // };

                let params = {
                    limit: this.pageSize > 0 ? this.pageSize : 10,
                    page: this.page,
                    isOwner: false,
                    isApprovals : true
                };
                if (this.search_string) {
                    params.search_string = this.search_string;
                }
                // if (this.order_by) {
                //     params.order_by = this.order_by;
                // }
                if (this.order_type) {
                    params.order_type = this.order_type;
                }
                if(this.selectedStatus) {
                    params.publish = this.selectedStatus;
                }
                // await this.$store.dispatch("entities/fetchEntities", params);
                await this.$store.dispatch("apps/fetchAllApplications", params);
                if (this.getAllApps) {
                    this.allApplications = this.getAllApps.data;
                    // this.allApplications = this.allApplications.filter(app => !app.is_admin_clone == true)
                    this.total = this.getAllApps && this.getAllApps.total ? this.getAllApps.total : 0;
                    this.currentPage = parseInt(this.getAllApps.page) || 1;
                    this.configureLoading = false;
                } else {
                    this.configureLoading = false;
                }
            } catch (err) {
                this.configureLoading = false;
                console.log(err);
            }
        },
        async onClone(row) {
            this.currentApp= {...row,isAdminClone:true,IsInstallAsWorkspace:true,newName:`eSigns.${row.name}`};
            this.installApplicationPopUp = true;
        },
        async cloneApplication(app) {
            this.appLoadingDialogVisible = true;
            let params = app
            // params.isAdminClone = true;
            // params.IsInstallAsWorkspace = true;
            // params.newName = `eSignss_${app.name}`;
            await this.$store.dispatch("apps/installApplication", params);
          if(this.getInstallationStatus){
            this.appLoadingDialogVisible = false;
            this.$message.success(params.name + " is Cloned");
            this.$router.push({ path: "/dashboard" });
            window.location.reload();
          }
          else{
            this.appLoadingDialogVisible = false;
          return this.$notify.error({
            title: "Error",
            message: this.getAppInstallationErrors ||  'Error installing app'
          });
          }
            
        },
        async onApprove(row) {
            this.$confirm("Are you sure to Approve the Application?", "Warning", {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning"
            }).then(() => {
                this.approveApplication(row);
            });
        },
        async approveApplication(app) {
            let params = {
                _id : app._id,
                updateData : {
                publish : 'ACTIVE'
                }
            }
            await this.$store.dispatch("apps/updateApplication", params);
            if(this.getAppUpdatedStatus){
                this.$message.success(app.name + " is Approved");
                this.fetchApplications()
            }
            else{
                return this.$notify.error({
                title: "Error",
                message: this.getAppUpdatedErrors ||  'Error Approving app'
                });
            }
        },
        async onDelete(row) {
            this.$confirm("Are you sure to delete the Application?", "Warning", {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning"
            }).then(() => {
                this.deleteApplication(row);
            });
        },
        async deleteApplication(app) {
            let params = app
            await this.$store.dispatch("apps/deleteApplication", params);
            if (this.getAppDeletionStatus) {
                this.fetchApplications();
                return this.$notify.success({
                    title: "Success",
                    message: "Application deleted successfully"
                });
            } else {
                this.$notify.error({
                    title: "Error",
                    message: this.getAppDeletionErrors || "Error on deleting App"
                });
            }
        },
        async onDecline(row) {
            this.$confirm("Are you sure to decline the Application? All cloned data will be removed from your account", "Warning", {
                confirmButtonText: "Decline",
                cancelButtonText: "Cancel",
                type: "warning"
            }).then(() => {
                // this.declineApplication(row);
                this.declineReasonPopup = true;
                this.currentApp = row;
            });
        },
        async declineReason(row){
            row['others'] = {declineReason : this.reasonForDecline}
            this.declineApplication(row);
        },
        async declineApplication(app) {
            this.configureLoading = true;
            let params = {
                _id : app._id,
                updateData : {
                publish : 'DECLINED',
                others : app.others
                }
            }

            await this.$store.dispatch("apps/updateApplication", params);
            if(this.getAppUpdatedStatus){
                this.configureLoading = false;
                this.$message.success(app.name + " is declined");
                window.location.reload();
                this.fetchApplications()
            }
            else{
                this.configureLoading = false;
                return this.$notify.error({
                title: "Error",
                message: this.getAppUpdatedErrors ||  'Error Declining app'
                });
            }
        },
        async onInactive(row) {
            this.$confirm("Are you sure to Inactive the Application?", "Warning", {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning"
            }).then(() => {
                this.inActiveApplication(row);
            });
        },
        async inActiveApplication(app) {
            let params = {
                _id : app._id,
                updateData : {
                publish : 'INACTIVE'
                }
            }
            await this.$store.dispatch("apps/updateApplication", params);
            if(this.getAppUpdatedStatus){
                this.$message.success(app.name + "is  inactivated");
                this.fetchApplications()
            }
            else{
                return this.$notify.error({
                title: "Error",
                message: this.getAppUpdatedErrors ||  'Error Declining app'
                });
            }
        },
        async onActive(row) {
            this.$confirm("Are you sure to active the Application?", "Warning", {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning"
            }).then(() => {
                this.ActiveApplication(row);
            });
        },
        async ActiveApplication(app) {
            let params = {
                _id : app._id,
                updateData : {
                publish : 'ACTIVE'
                }
            }
            await this.$store.dispatch("apps/updateApplication", params);
            if(this.getAppUpdatedStatus){
                this.$message.success(app.name + " is activated");
                this.fetchApplications()
            }
            else{
                return this.$notify.error({
                title: "Error",
                message: this.getAppUpdatedErrors ||  'Error Declining app'
                });
            }
        },
        async getCompanyInfo() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "company/fetchCompany",
          this.getActiveWorkspace.company_id
        );
        if (this.getCompanyDetails) {
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
        },
    },
    watch: {
        "$route.query.group"() {
            this.fetchApplications();
            // this.currentSlug = slug;
        },
        "$route.query.key"() {
            this.fetchApplications();
        },
        "selectedStatus" : async function() {
            this.fetchApplications();
        },
        "search_string" : async function (){
            this.fetchApplications();
        }
    }
};
</script>
<style scoped lang="scss">
   .circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;
    &::after {
      background: #635e5e;
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }
    &.active::after {
      background: #08ad36;
    }
    &.hold::after {
      background: #f19203;
    }
    &.onreview::after {
      background: #2f80ed;
    }
    &.inactive::after {
      background: #756a6a;
    }
    &.declined::after {
      background: #f03232;
    }
    &.ACTIVE::after {
        background: #08ad36;
      }
      &.HOLD::after {
        background: #f19203;
      }
      &.ONREVIEW::after {
        background: #2f80ed;
      }
      &.INACTIVE::after {
        background: #756a6a;
      }
      &.DECLINED::after {
        background: #f03232;
      }

  }
.card-content {
    display: flex;
    justify-content: space-between;
}

.left-content {
    display: flex;
    align-items: center;
}

.right-content {
    margin-left: auto;
}

.el-card {
    font-size: 12px;
}

.el-card h5 {
    font-size: 14px;
}

.card-button {
    border-radius: 5px;
}

.import-loading-gif {
  height: 30vh;
  width: 15vw;
}
.imgSize {
    height: 4.5em;
    width: 4.5em;
    margin-right: 5px
}

.doc-title {
    cursor: pointer;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    line-height: 14px;
    text-align: left;
    color: #6c757d !important;
}

.activestatus {
    font-size: 14px;
    color: #6c757d !important;
}

.circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;

    &::after {
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        height: 10px;
        width: 10px;
        display: inline-block;
        border-radius: 50%;
    }

    &.active::after {
        background: #08ad36;
    }

    &.inactive::after {
        background: #585557;
    }

    &.deleted::after {
        background: #8b1702;
    }

    &.archived::after {
        background: #404040;
    }

    &.disable::after {
        background: #c4c4c4;
    }

}
</style>
<style lang="scss">
.top-buttons-wrapper {
    @media (max-width: 991.98px) {
        display: flex;
        margin-top: 20px;
    }
}

.new-entity-information {
    .el-dialog {
        width: 800px;
        padding-bottom: 15px;

        @media (max-width: 800px) {
            width: 700px;
            margin-top: 40px !important;
        }

        @media (max-width: 700px) {
            width: 600px;
            margin-top: 40px !important;
        }

        @media (max-width: 600px) {
            width: 500px;
            margin-top: 40px !important;
        }

        @media (max-width: 500px) {
            width: 400px;
            margin-top: 40px !important;
        }

        @media (max-width: 400px) {
            width: 300px;
            margin-top: 40px !important;
        }

        .entity-type-radio {
            @media (max-width: 400px) {
                &>label {
                    &>span {
                        padding-left: 5px !important;
                        padding-right: 5px !important;
                    }
                }
            }
        }
    }
}
</style>